import { Formik, Form, FieldArray, ErrorMessage, Field } from "formik";
import { Input, Select, Radio, List } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { DEGREE_OPTIONS } from "./utils";
import * as Yup from "yup";
import Heading from "./heading";
import { useEffect, useState } from "react";

const Step1 = ({
    onSubmit,
    initialValues,
    formRef,
    clientOptions,
    departmentOptions,
    onDepartmentChange,
    roles,
    onRoleChange,
}) => {
    const [selectedRole, setSelectedRole] = useState(initialValues.role);

    return (
        <>
            <Heading label="Create a new Job" />
            <div className="mt-16">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        if (!selectedRole) {
                            alert("Please select a role");
                            return;
                        }
                        onSubmit({ ...values, role: selectedRole });
                    }}
                    validationSchema={Yup.object({
                        client: Yup.string().required("Required"),
                        department: Yup.string().required("Required"),
                        description: Yup.string().required("Required"),
                    })}
                    innerRef={formRef}
                >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="w-[30rem]" id="select-input">
                                <Select
                                    className="w-[100%] h-[5rem] border-[#ACACAC]"
                                    placeholder="Choose Client"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input)
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    onChange={(value) => {
                                        props.setFieldValue("client", value);
                                    }}
                                    value={props.values.client}
                                    options={clientOptions}
                                />
                            </div>
                            <div className="w-[30rem] mt-10" id="select-input">
                                <Select
                                    className="w-[100%] h-[5rem] border-[#ACACAC]"
                                    placeholder="Choose Department"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input)
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare((optionB?.label ?? "").toLowerCase())
                                    }
                                    onChange={(value) => {
                                        props.setFieldValue("department", value);
                                        onDepartmentChange(value);
                                    }}
                                    value={props.values.department}
                                    options={departmentOptions}
                                />
                            </div>
                            {/* Roles Radio Buttons */}
                            {roles && roles.length > 0 && (
                                <div style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
                                    {roles.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setSelectedRole(item);
                                                onRoleChange(item);
                                            }}
                                            style={{
                                                border: "1px solid #ACACAC",
                                                borderRadius: "9999px",
                                                padding: "10px 20px",
                                                cursor: "pointer",
                                                backgroundColor:
                                                    selectedRole === item ? "#4096ff" : "#fff",
                                                color: selectedRole === item ? "#fff" : "#000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="flex flex-col mt-10">
                                <label className="font-[600] text-[24px] text-[#000]">
                                    Add a Job Description*
                                </label>
                                <Field
                                    name="description"
                                    as="textarea"
                                    placeholder="Enter job description"
                                    className="mt-4 text-[18px] pl-3 py-2 w-[32rem] border border-[#ACACAC] rounded-lg outline-none focus:outline-none focus:border-[#4096ff] focus:shadow-none hover:border-[#4096ff]"
                                    style={{
                                        resize: "none",
                                        height: "8rem",
                                    }}
                                />
                                <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="text-red-500"
                                />
                            </div>

                            <div className="flex flex-col mt-10">
                                <label className="font-[600] text-[24px] text-[#000]">
                                    Fresher Allowed
                                </label>
                                <Radio.Group
                                    onChange={(e) =>
                                        props.setFieldValue("is_fresher_allowed", e.target.value)
                                    }
                                    value={props.values.is_fresher_allowed}
                                    className="mt-4 font-[600] text-[24px]"
                                    buttonStyle="solid"
                                    size="large"
                                >
                                    <Radio value={false} className="text-[24px]">
                                        No
                                    </Radio>
                                    <Radio value={true} className="text-[24px]">
                                        Yes
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};


const Step2 = ({
    onSubmit,
    initialValues,
    formRef,
    stateOptions,
    getCities,
}) => {
    return (
        <>
            <Heading label="Add Job Location & Details" />
            <div className="mt-16">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object({
                        state: Yup.string().required("Required"),
                        city: Yup.string().required("Required"),
                    })}
                    innerRef={formRef}
                >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="flex flex-row">
                                {/* State Selection */}
                                <div className="w-[30rem] mt-10" id="select-input">
                                    <Select
                                        className="w-[100%] h-[5rem] border-[#ACACAC]"
                                        placeholder="Select State"
                                        onChange={(value) => {
                                            props.setFieldValue("state", value);
                                            props.setFieldValue("city", null); // Reset city when state changes

                                            // Find and set the stateCode based on selected state
                                            const stateCode = stateOptions.find(
                                                (state) => state.value === value
                                            )?.isoCode;
                                            props.setFieldValue("stateCode", stateCode);
                                        }}
                                        value={props.values.state}
                                        options={stateOptions}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? "")
                                                .toLowerCase()
                                                .localeCompare((optionB?.label ?? "").toLowerCase())
                                        }
                                    />
                                    <ErrorMessage name="state" component="div" className="text-red-500" />
                                </div>

                                {/* City Selection */}
                                <div className="w-[30rem] mt-10 ml-10" id="select-input">
                                    {props.values.stateCode && (
                                        <Select
                                            className="w-[100%] h-[5rem] border-[#ACACAC]"
                                            placeholder="Select City"
                                            onChange={(value) => {
                                                props.setFieldValue("city", value);
                                            }}
                                            value={props.values.city}
                                            options={getCities(props.values.stateCode)}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? "").toLowerCase().includes(input)
                                            }
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? "")
                                                    .toLowerCase()
                                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                                            }
                                        />
                                    )}
                                    <ErrorMessage name="city" component="div" className="text-red-500" />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};


const Step3 = ({ onSubmit, initialValues, formRef, isFresherAllowed }) => {
	return (
		<>
			<Heading label="Add Education Qualifications" />
			<div className="mt-16">
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => onSubmit(values)} // Only submit values when form is submitted
					innerRef={formRef}
					enableReinitialize // Allows Formik to reset state when initialValues changes
					validationSchema={Yup.object({
						education: Yup.array()
							.of(
								Yup.object().shape({
									name: Yup.string().required("Degree is required"),
									experience: isFresherAllowed
										? Yup.string() // If fresher is allowed, experience is optional
										: Yup.string().required("Experience is required"),
								})
							)
							.min(1, "At least one education qualification is required"),
					})}
				>
					{(props) => (
						<Form>
							<FieldArray
								name="education"
								render={(arrayHelpers) => (
									<div>
										{props.values.education.map((item, index) => (
											<div className="flex flex-row gap-x-10 mb-10" key={index}>
												{/* Degree Selection */}
												<div className="w-[35rem]" id="select-input">
													<Select
														className="w-[100%] h-[5rem] border-[#ACACAC]"
														placeholder="Select Degree"
														onChange={(value) => {
															props.setFieldValue(`education[${index}].name`, value);
														}}
														onBlur={() => onSubmit(props.values)} // Submit only on blur
														value={props.values.education[index].name}
														options={DEGREE_OPTIONS}
														showSearch
														optionFilterProp="children"
														filterOption={(input, option) =>
															(option?.label ?? "")
																.toLowerCase()
																.includes(input)
														}
														filterSort={(optionA, optionB) =>
															(optionA?.label ?? "")
																.toLowerCase()
																.localeCompare(
																	(optionB?.label ?? "").toLowerCase()
																)
														}
													/>
													<ErrorMessage
														name={`education[${index}].name`}
														component="div"
														className="text-red-500"
													/>
												</div>

												{/* Experience Input */}
												{!isFresherAllowed && (
													<div className="flex flex-row">
														<div className="w-[10rem] h-[5rem] text-[#000] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tl-[16px] rounded-bl-[16px] border-[#ACACAC] border">
															Experience
														</div>
														<Input
															size="large"
															onChange={props.handleChange}
															onBlur={(e) => {
																props.handleBlur(e);
																onSubmit(props.values); // Submit on blur for Experience
															}}
															value={props.values.education[index].experience}
															type="text"
															name={`education[${index}].experience`}
															className="w-[15rem] rounded-none border-x-0"
														/>
														<div className="w-[10rem] h-[5rem] text-[#000] bg-[#ECECEC] font-2xl font-[600] flex justify-center items-center rounded-tr-[16px] rounded-br-[16px] border-[#ACACAC] border">
															in Years
														</div>
														<ErrorMessage
															name={`education[${index}].experience`}
															component="div"
															className="text-red-500 ml-2"
														/>
													</div>
												)}

												{/* Remove Button */}
												{index > 0 && (
													<button
														type="button"
														className="w-[6.5rem] h-[4.5rem] bg-[#ECECEC] rounded-[16px] border-[#ACACAC] border flex justify-center items-center"
														onClick={() => {
															arrayHelpers.remove(index);
															onSubmit(props.values); // Update projectInfo on removal
														}}
													>
														<MinusOutlined
															style={{
																color: "#818181",
																fontSize: "20px",
															}}
														/>
													</button>
												)}
											</div>
										))}

										{/* Add Button */}
										{props.values.education.length < 3 && (
											<button
												type="button"
												onClick={() => {
													arrayHelpers.push({ name: null, experience: "" });
													onSubmit(props.values); // Update projectInfo on addition
												}}
												className="w-[6.5rem] h-[4.5rem] bg-[#ECECEC] rounded-[16px] border-[#ACACAC] border flex justify-center items-center"
											>
												<PlusOutlined
													style={{
														color: "#818181",
														fontSize: "20px",
													}}
												/>
											</button>
										)}
									</div>
								)}
							/>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};




export { Step1, Step2, Step3 };
