import React, { useCallback, useEffect, useState, useContext } from 'react'

import EnquiryCard from './enquiry-card'
import { projectFireStore } from '../../../firebase/firebase'
import InfiniteScroll from 'react-infinite-scroll-component'

const itemsPerPage = 10

const Enquiry = () => {
  const [enquires, setEnquires] = useState([])
  const [totalEnqueries, setTotalEnqueries] = useState(0)
  const [lastDoc, setLastDoc] = useState(null) // Keep track of the last document

  const fetchData = async () => {
    let query = projectFireStore
      .collection('enquiry')
      .orderBy('createdAt', 'desc')
      .limit(2)

    if (lastDoc) {
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(itemsPerPage).get()

    const newEnquires = snapshot.docs.map((doc) => doc.data())
    setEnquires([...enquires, ...newEnquires])

    // Get total count
    const totalSnapshot = await projectFireStore.collection('enquiry').get()
    setTotalEnqueries(totalSnapshot.size)

    // Update the last document
    const lastDocInPage = snapshot.docs[snapshot.docs.length - 1]
    setLastDoc(lastDocInPage)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className=''>
      <div className='flex flex-row justify-between items-center'>
        <h3 className='text-[56px] text-[#000] font-[600]'>Enquiry at RCL</h3>
        <p>
          Total enquires:{' '}
          <span className='text-blue font-medium'>{totalEnqueries}</span>
        </p>
      </div>

      <InfiniteScroll
        dataLength={enquires.length} //This is important field to render the next data
        next={fetchData}
        hasMore={enquires.length < totalEnqueries} // Stop loading when all data is loaded
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div className='grid grid-cols-1 gap-8 mt-4'>
          {enquires.map((enquiry) => (
            <EnquiryCard key={enquiry.enquiry_id} {...enquiry} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default Enquiry

// const getEnquires = async () => {
//   const snapshot = await projectFireStore
//     .collection("enquiry")
//     .orderBy("createdAt", "desc")
//     .get();

//   const allEnquires = snapshot.docs.map((doc) => doc.data());

//   const emailMessagesMap = new Map();
//   const filteredEnquires = allEnquires.filter((enquiry) => {
//     if (!enquiry.email || !enquiry.message || !enquiry.name) {
//       return false; // Filter out enquiries missing email, message, or name
//     }

//     const messagesSet = emailMessagesMap.get(enquiry.email) || new Set();
//     if (messagesSet.has(enquiry.message)) {
//       // Duplicate email with same message content found
//       return false;
//     } else {
//       // Add new message content for this email
//       messagesSet.add(enquiry.message);
//       emailMessagesMap.set(enquiry.email, messagesSet);
//       return true; // Keep this enquiry
//     }
//   });

//   setEnquires(filteredEnquires || []); // Use the filtered list
//   setTotalEnqueries(filteredEnquires.length); // Total enquiries before filtering
// };
