import React, { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

const ProfileCard = ({ user, onShowComments, commentsCount, onRemove }) => {
  const { is_viewed = false, is_shortlisted = false } = user;
  const [isRejecting, setIsRejecting] = useState(false);

  const onViewedHandle = async () => {
    if (is_viewed) return;

    const resumeRef = doc(db, 'resumes', user.resume_id);

    try {
      await updateDoc(resumeRef, {
        is_viewed: true,
      });
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const onRejectHandle = async () => {
    setIsRejecting(true);
    const resumeRef = doc(db, 'resumes', user.resume_id);

    try {
      await updateDoc(resumeRef, {
        is_rejected: true,
      });
      onRemove(user.resume_id); // Remove the resume from the list
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setIsRejecting(false);
    }
  };

  return (
    <div className='border-[#E9EDF3] border-2 rounded-xl p-10'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row items-center gap-2'>
          <h3 className='text-lg md:text-xl lg:text-3xl font-semibold text-rclBlack'>
            {user.name}
          </h3>
          {is_viewed && (
            <div className='w-[64px] h-[24px] border border-solid border-[#E8E8E8] rounded-lg flex justify-center items-center'>
              <h3 className='text-[12px] font-normal text-rclBlack'>Viewed</h3>
            </div>
          )}
        </div>
        <div className='flex flex-row items-center gap-6'>
          <a
            href={user.resume}
            className='text-[#000]'
            target='_blank'
            onClick={onViewedHandle}
          >
            <div className='w-fit h-fit rounded-full px-4 py-2  bg-rclYellow cursor-pointer flex justify-center items-center hover:text-[#FFF] border-2 border-[#FFF] hover:border-2 hover:bg-[#FFF] hover:border-rclGrey transition-all duration-300'>
              <h3 className='font-medium text-rclBlack text-lg text-center'>
                Show Resume
              </h3>
            </div>
          </a>
          <div
            onClick={() => onShowComments(user)}
            className={`w-fit h-fit rounded-full px-4 py-2 bg-rclGreenAlpha cursor-pointer flex justify-center items-center hover:text-[#FFF] border-2 border-[#FFF] hover:border-2 hover:bg-[#FFF] hover:border-rclGrey transition-all duration-300`}
          >
            <h3
              className={`font-medium text-rclBlack text-lg text-center`}
            >
              Show Comments: {commentsCount}
            </h3>
          </div>
          <button
            onClick={onRejectHandle}
            className={`w-fit h-fit font-medium rounded-full px-4 py-2 bg-[#FFB1B3] cursor-pointer flex justify-center items-center hover:text-[#000] border-2 border-[#FFF] hover:border-2 hover:bg-[#FFF] hover:border-rclGrey transition-all duration-300 ${
              isRejecting ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
            }`}
            disabled={isRejecting}
          >
            {isRejecting ? (
              <div className="w-5 h-5 border-4 border-t-4 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              'Reject'
            )}
          </button>
        </div>
      </div>
      <h3 className='font-[400] text-[#000] text-xl mt-2 mb-3 w-[75%]'>
        {user.message}
      </h3>
      <div className='flex flex-row'>
        <a href={`mailto:${user.email}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#97019A] cursor-pointer flex justify-center items-center'>
            <MailOutlined style={{ color: '#97019A' }} />
            <h3 className='font-[400] text-xl text-[#97019A] text-center ml-2'>
              {user.email}
            </h3>
          </div>
        </a>
        <a href={`tel:${user.phone}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#02AE66] cursor-pointer flex justify-center items-center ml-5'>
            <PhoneOutlined style={{ color: '#02AE66', transform: 'rotate(90deg)' }} />
            <h3 className='font-[400] text-xl text-[#02AE66] text-center ml-2'>
              {user.phone}
            </h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProfileCard;