import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const CommentSidebar = ({ resume_id, name, show, onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  // Toggle body scroll when the sidebar opens or closes
  useEffect(() => {
    if (show) {
      // Disable body scroll
      document.body.style.overflow = 'hidden';
    } else {
      // Enable body scroll
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to ensure body scroll is re-enabled on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  // Fetch comments from the "comments" sub-collection under the specific resume document
  useEffect(() => {
    const fetchComments = async () => {
      if (!resume_id) return;

      const commentsRef = collection(db, 'resumes', resume_id, 'comments'); // Access comments sub-collection

      try {
        const querySnapshot = await getDocs(commentsRef);
        const commentsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(commentsData);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    if (show) {
      fetchComments();
    }
  }, [resume_id, show]);

  // Add a new comment to the "comments" sub-collection
  const handleAddComment = async () => {
    if (newComment.trim() === '') return;

    const commentData = {
      text: newComment,
      timestamp: serverTimestamp(),
    };

    try {
      const commentsRef = collection(db, 'resumes', resume_id, 'comments'); // Reference to comments sub-collection
      const docRef = await addDoc(commentsRef, commentData);
      
      // Update comments list immediately after adding new comment
      setComments((prev) => [
        ...prev,
        { id: docRef.id, ...commentData, timestamp: new Date() }, // Temporary timestamp until serverTimestamp is resolved
      ]);
      setNewComment(''); // Clear input field
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
          show ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        style={{ zIndex: 40 }}
        onClick={onClose}
      ></div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transition-transform transform ${
          show ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{
          width: '100%',
          maxWidth: '33vw',
          height: '100vh',
          zIndex: 50,
          backgroundColor: '#ffffff',
        }}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-2xl font-semibold">Comments for {name}</h3>
            <button onClick={onClose} className="text-lg font-semibold text-gray-500">
              Close
            </button>
          </div>

          {/* Comments List - Scrollable */}
          <div className="flex-1 overflow-y-auto p-4">
            <p className="text-gray-500 mb-2">Total Comments: {comments.length}</p>
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div key={comment.id} className="border-b border-gray-300 p-2">
                  <p>{comment.text}</p>
                  <small className="text-gray-500">
                    {comment.timestamp?.seconds
                      ? new Date(comment.timestamp.seconds * 1000).toLocaleString()
                      : 'Just now'}
                  </small>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No comments available</p>
            )}
          </div>

          {/* Footer - Add Comment */}
          <div className="p-4 border-t relative">
            <textarea
              className="w-full p-2 border rounded resize-none"
              rows="3"
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <button
              onClick={handleAddComment}
              className={`absolute bottom-4 right-4 bg-blue-500 text-white rounded-full px-4 py-2 transition ${
                newComment.trim() === '' ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
              }`}
              disabled={newComment.trim() === ''}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentSidebar;
