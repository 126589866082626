import { projectFireStore, timeStamp } from '../../../firebase/firebase'

const DEGREE_OPTIONS = [
  {
    value: 'B.Tech/Diploma in Civil Engineering',
    label: 'B.Tech/Diploma in Civil Engineering',
  },
  {
    value: 'Diploma/BE/B.Tech in Civil Engineering',
    label: 'Diploma/BE/B.Tech in Civil Engineering',
  },
  {
    value: 'B.Tech in Mechanical Engineering',
    label: 'B.Tech in Mechanical Engineering',
  },
  {
    value: 'B.Tech/Diploma in Mechanical Engineering',
    label: 'B.Tech/Diploma in Mechanical Engineering',
  },
  {
    value: 'B.Tech in Civil Engineering',
    label: 'B.Tech in Civil Engineering',
  },
  {
    value: 'B.Tech/Diploma',
    label: 'B.Tech/Diploma',
  },
  {
    value: 'Degree/Diploma',
    label: 'Degree/Diploma',
  },
  {
    value: 'B.Tech/Diploma in Civil/Mechanical Engineering',
    label: 'B.Tech/Diploma in Civil/Mechanical Engineering',
  },
  {
    value: 'Post Graduation',
    label: 'Post Graduation',
  },
  {
    value: 'Graduate',
    label: 'Graduation',
  },
  {
    value: 'B.Com and qualified CA part-A (Intermediate)/ICWA',
    label: 'B.Com and qualified CA part-A (Intermediate)/ICWA',
  },
  {
    value: 'M.Com and qualified CA part-A (Intermediate)/ICWA',
    label: 'M.Com and qualified CA part-A (Intermediate)/ICWA',
  },
  {
    value: 'BS/Diploma in Safety Engineering',
    label: 'BS/Diploma in Safety Engineering',
  },
  {
    value: 'Post graduate in Charted Accountant (CA)',
    label: 'Post graduate in Charted Accountant (CA)',
  },
  {
    value: 'B.Com/LLB/MBA',
    label: 'B.Com/LLB/MBA',
  },
]

export const parseDataToBackend = (data, selectedClient, selectedDepartment) => {
  const clientRef = `/clients/${selectedClient.value}`
  const departmentRef = `/departments/${selectedDepartment.value}`
  const dummyDesc = "Candidate should have experinece in costing and accounting of Road and Highways project"

  return {
    category: selectedDepartment.label,
    name: data.role,
    client_ref: clientRef,
    designation_ref: departmentRef,
    description: data.description || dummyDesc, // Use provided description if available
    is_fresher_allowed: data.is_fresher_allowed,
    department_opening: 4, // Default value

    // Locations: Handle city and state data from Step 2
    locations: data.city && data.state ? [{ city: data.city, state: data.state }] : [],

    // Educations: Handle education array from Step 3
    educations: data.is_fresher_allowed
      ? [{ name: data.education[0].name, experience: null }]
      : data.education.map(edu => ({
          name: edu.name,
          experience: edu.experience,
        })),

    created_at: timeStamp(),
    job_color: '#FFF000', // Static color
    is_active: true, // Default active status
  };
}

export const parseClientOptions = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.client_id,
  }))
}

export const parseDepartmentOptions = (departments) => {
  return departments.map((department) => ({
    label: department.name,
    value: department.department_id
  }));
};

export { DEGREE_OPTIONS }
