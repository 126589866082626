import {
    useState,
    useRef,
    useMemo,
    useContext,
    useCallback,
    useEffect,
} from "react";
import { Steps, Modal } from "antd";
import { collection, doc, setDoc } from "firebase/firestore";
import { City, State } from "country-state-city";
import { UserContext } from "../../../context";
import { useNavigate } from "react-router-dom";

import {
    parseDataToBackend,
    parseDepartmentOptions,
    parseClientOptions,
} from "./utils";
import { db, projectFireStore } from "../../../firebase/firebase";
import { Step1, Step2, Step3 } from "./steps";

const Stepper = () => {
	const [current, setCurrent] = useState(0);
	const [projectInfo, setProjectInfo] = useState({
		0: {
			client: null,
			department: null,
			description: "",
			is_fresher_allowed: false,
			role: null,
		},
		1: { state: null, city: null },
		2: { education: [{ name: null, experience: "1" }] },
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [clientOptions, setClientOptions] = useState([]);
	const [fullDepartmentData, setFullDepartmentData] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [selectedDepartment, setSelectedDepartment] = useState(null);

	const navigate = useNavigate();
	const formRef = useRef();

	useEffect(() => {
		getClients();
		getDepartments();
	}, []);

	useEffect(() => {
		console.log("Updated projectInfo:", projectInfo);
	}, [projectInfo]);

	const getClients = async () => {
		const snapshot = await projectFireStore.collection("clients").get();
		let clients = parseClientOptions(snapshot.docs.map((doc) => doc.data()));
		setClientOptions(clients);
	};

	const getDepartments = async () => {
		const snapshot = await projectFireStore.collection("departments").get();
		let departments = snapshot.docs.map((doc) => ({
			...doc.data(),
			department_id: doc.id,
		}));
		setFullDepartmentData(departments);
		setDepartmentOptions(parseDepartmentOptions(departments));
	};

	const onDepartmentChange = (departmentId) => {
		const selectedDept = fullDepartmentData.find(
			(dept) => dept.department_id === departmentId
		);
		setSelectedDepartment(selectedDept);
	};

	const onRoleChange = (selectedRole) => {
		setProjectInfo((prev) => ({
			...prev,
			[current]: {
				...prev[current],
				role: selectedRole,
			},
		}));
	};

	const stateOptions = useMemo(
		() =>
			State.getStatesOfCountry("IN").map((state) => ({
				label: state.name,
				value: state.name,
				isoCode: state.isoCode,
			})),
		[]
	);

	const getCities = useCallback((stateCode) => {
		let cities = City.getCitiesOfState("IN", stateCode).map((city) => ({
			label: city.name,
			value: city.name,
		}));
		return cities;
	}, []);

	const next = () => {
		if (current < steps.length - 1) {
			// If not the last step, proceed to next step
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			setCurrent((prev) => prev + 1);
		} else {
			// If it's the last step, call onSubmitHandle for final submission
			onSubmitHandle();
		}
	};

	const prev = () => {
		setCurrent((prev) => prev - 1);
	};

	const onSubmit = (val) => {
		setProjectInfo((prev) => ({
			...prev,
			[current]: val,
		}));
	};

	const onSubmitHandle = async () => {
		try {
			const selectedClients = clientOptions.find(
				(client) => client.value === projectInfo["0"].client
			);

			const selectedDepartments = departmentOptions.find(
				(department) => department.value === projectInfo["0"].department
			);

			const combinedData = {
				...projectInfo["0"],  // Includes client, department, description, etc.
				...projectInfo["1"],  // Includes state, city
				...projectInfo["2"],  // Includes education array
			};

			const parsedData = parseDataToBackend(
				combinedData,
				selectedClients,
				selectedDepartments
			);

			const newDocRef = doc(collection(db, "jobs"));
			const docId = newDocRef.id;

			console.log("Data to be submitted:", parsedData);

			// Uncomment this to enable actual submission to Firestore
			await setDoc(newDocRef, {
			    ...parsedData,
			    job_id: docId,
			});

			alert("Job added successfully");
			navigate("/admin/jobs");
		} catch (err) {
			console.log("Error:", err);
		}
	};
	
	

	const steps = [
		{
			title: "First",
			content: (
				<Step1
					onSubmit={onSubmit}
					initialValues={projectInfo["0"]}
					formRef={formRef}
					clientOptions={clientOptions}
					departmentOptions={departmentOptions}
					onDepartmentChange={onDepartmentChange}
					roles={selectedDepartment ? selectedDepartment.roles : []}
					onRoleChange={onRoleChange}
				/>
			),
		},
		{
			title: "Second",
			content: (
				<Step2
					onSubmit={onSubmit}
					initialValues={projectInfo["1"]}
					formRef={formRef}
					stateOptions={stateOptions}
					getCities={getCities}
				/>
			),
		},
		{
			title: "Last",
			content: (
				<Step3
					onSubmit={onSubmit}
					initialValues={projectInfo["2"]}
					formRef={formRef}
					isFresherAllowed={projectInfo["0"].is_fresher_allowed}
				/>
			),
		},
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const onOk = () => {
		setIsModalOpen(false);
		navigate("/admin/jobs");
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const items = useMemo(
		() =>
			steps.map((item) => ({
				key: item.title,
				title: item.title,
			})),
		[steps]
	);

	return (
		<>
			<div className="bg-[#F9F9F9] h-[5rem] w-full flex flex-row items-center px-10">
				<div className="basis-1/3">
					<h3
						className="text-2xl cursor-pointer text-[#000] font-[600] inline-block"
						onClick={showModal}
					>
						Cancel
					</h3>
				</div>
				<div className="basis-1/3">
					<Steps current={current} items={items} />
				</div>
				<div className="basis-1/3"></div>
			</div>

			<div className="px-20 py-20">
				<div>{steps[current].content}</div>

				<div className="mt-16">
					{current > 0 && (
						<button
							onClick={prev}
							className="w-[11rem] h-[3rem] border-2 border-[#FFCC33] text-[#FFCC33] font-[600] rounded-full mr-8"
							type="button"
						>
							Previous
						</button>
					)}
					{current < steps.length - 1 && (
						<button
							className="w-[11rem] h-[3rem] bg-[#FFCC33] text-[#000] font-[600] rounded-full"
							onClick={next}
							type="button"
						>
							Continue
						</button>
					)}
					{current === steps.length - 1 && (
						<button
							className="w-[11rem] h-[3rem] bg-[#FFCC33] text-[#000] font-[600] rounded-full"
							onClick={next}
							type="button"
						>
							Add Job
						</button>
					)}
				</div>
			</div>

			<Modal title="Cancel" open={isModalOpen} onOk={onOk} onCancel={handleCancel}>
				<p>Are you sure you want to cancel?</p>
			</Modal>
		</>
	);
};

export default Stepper;
