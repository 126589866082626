const MENU = [
  {
    label: 'Dashboard',
    to: '/admin/dashboard',
  },
  {
    label: 'Projects',
    to: '/admin/projects',
  },
  {
    label: 'Careers',
    to: '/admin/jobs',
  },
  {
    label: 'Enquiry',
    to: '/admin/enquiry',
  },
]

const CAREER_MENU = [
  {
    label: 'Dashboard',
    to: '/admin/dashboard',
  },
  {
    label: 'Careers',
    to: '/admin/jobs',
  },
  {
    label: 'Enquiry',
    to: '/admin/enquiry',
  },
]

const PROJECT_MENU = [
  {
    label: 'Dashboard',
    to: '/admin/dashboard',
  },
  {
    label: 'Projects',
    to: '/admin/projects',
  },
  {
    label: 'Enquiry',
    to: '/admin/enquiry',
  },
]

export { CAREER_MENU, MENU, PROJECT_MENU }
