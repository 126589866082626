import React from 'react'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import moment from 'moment'

function toDateTime(seconds) {
  const milliseconds = seconds * 1000
  const dateObject = moment(milliseconds)
  const formattedDate = dateObject.format('DD MMM YYYY')

  return formattedDate
}

const EnquiryCard = (props) => {
  const { email, message, name, phone, createdAt } = props || {}

  return (
    <div className='border-[#E9EDF3] border-2 rounded-xl p-10'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row flex-1 items-center justify-between gap-2'>
          <h3 className='text-lg md:text-xl lg:text-3xl font-semibold text-rclBlack'>
            {name}
          </h3>
          <h3 className='text-sm font-semibold text-rclBlack'>
            <span className='opacity-75'>Received on: </span>
            {toDateTime(createdAt.seconds)}
          </h3>
        </div>
      </div>
      <h3 className='font-[400] text-[#000] text-xl mt-2 mb-3'>{message}</h3>
      <div className='flex flex-row'>
        <a href={`mailto:${email}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#97019A] cursor-pointer flex justify-center items-center'>
            <MailOutlined style={{ color: '#97019A' }} />
            <h3 className='font-[400] text-xl text-[#97019A] text-center ml-2'>
              {email}
            </h3>
          </div>
        </a>
        <a href={`tel:${phone}`}>
          <div className='px-4 py-2 min-w-[11rem] h-[2.5rem] rounded-3xl bg-[#FAFCFF] border border-[#02AE66] cursor-pointer flex justify-center items-center ml-5'>
            <PhoneOutlined
              style={{ color: '#02AE66', transform: 'rotate(90deg)' }}
            />
            <h3 className='font-[400] text-xl text-[#02AE66] text-center ml-2'>
              {phone}
            </h3>
          </div>
        </a>
      </div>
    </div>
  )
}

export default EnquiryCard
